
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "edit-role-modal",
    emit: ["refreshData"],
    props: {
        role: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let isUpdate = false as boolean;
        let permissions = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editRoleModalRef = ref<null | HTMLElement>(null);
        const roleDefault = {
            id  : null,
            name: "",
            permissions : [],
        };
        const roleData = ref<any>({});
        Object.assign(roleData.value, roleDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            name: Yup.string().required().label(translate("roleNameLabel")),
        });

        const getRole = async (id: number) => {
            const response = await axios.get(`${ApiRoutes.roles.read}/${id}`);

            roleData.value = response.data;

            console.log(roleData.value);
        };

        const getPermissions = async () => {
            const response = await axios.get(ApiRoutes.roles.permissions);

            permissions.value = response.data;
        };

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");

            if (isUpdate && props.role.id) roleData.value.id = props.role.id;

            axios.post(ApiRoutes.roles.updateOrCreate, roleData.value)
                .then((response: AxiosResponse) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editRoleModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(() => {
            getPermissions();

            const modal = document.getElementById('kt_modal_edit_role');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(roleData.value, roleDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;

                if (props.role) {
                    isUpdate = true;

                    // Fill Intra fields
                    Object.assign(roleData.value, props.role);

                    getRole(Number(roleData.value.id));
                }
            });
        });

        return {
            translate,
            permissions,
            roleData,
            validationSchema,
            submit,
            submitButtonRef,
            editRoleModalRef,
        };
    },
});
